import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Toaster } from 'react-hot-toast';

// import Detail from './pages/Detail';
import Auth from './utils/auth';
import { PrivateRoutes, AdminRoutes } from './utils/privateRoutes';
// import { StoreProvider } from './utils/GlobalState';



const Scanner = lazy(() => import('./pages/Scanner'))

const Home = lazy(() => import('./pages/Home'));
const Detail = React.lazy(() => import('./pages/Detail'))
const ArtistDetail = lazy(() => import('./pages/ArtistDetail'));
const AllArtists = lazy(() => import('./pages/AllArtists'))
const PlaylistDetail = lazy(() => import('./pages/PlaylistDetail'));
const MyPlaylists = lazy(() => import('./pages/MyPlaylists'));
const ConfirmTickets = lazy(() => import('./pages/ConfirmTickets'));
const Payment = lazy(() => import('./components/Payment'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Admin = lazy(() => import('./pagesAdmin/Admin'));
const ManageArtists = lazy(() => import('./pagesAdmin/ManageArtists'));
const ManageTracks = lazy(() => import('./pagesAdmin/ManageTracks'));
const OrganizerAdmin = lazy(() => import('./pagesAdmin/OrganizerAdmin'));
const OrganizerLogin = lazy(() => import('./pagesAdmin/OrganizerLogin'));
const OrganizerEvent = lazy(() => import('./pagesAdmin/OrganizerEvent'));
const AdminLogin = lazy(() => import('./pagesAdmin/Login'));
const Success = lazy(() => import('./pages/Success'));
const OrderHistory = lazy(() => import('./pages/OrderHistory'));
const Searches = lazy(() => import('./pages/Search'));
const Terms = lazy(() => import('./pages/Terms&Conditions'));
const Policies = lazy(() => import('./pages/Policies'));
const Contact = lazy(() => import('./pages/Contact'));

// Spanish Pages
const TermsES = lazy(() => import('./pagesES/Terms&Conditions'));
const PoliciesES = lazy(() => import('./pagesES/Policies'));

const Vote = lazy(() => import('./votingPages/vote'));


const AudioPlayer = lazy(() => import('./musicPlayer/AudioPlayer'))
const AudioPlayer2 = lazy(() => import('./musicPlayer2/AudioPlayer2'))
const AudioPlayer3 = lazy(() => import('./musicPlayer3/AudioPlayer2'))
const AudioPlayer4 = lazy(() => import('./musicPlayer4/AudioPlayer2'))
const AudioPlayer5 = lazy(() => import('./musicPlayer5/AudioPlayer2'))
const AudioPlayer6 = lazy(() => import('./musicPlayer6/AudioPlayer2'))
const AudioPlayer7 = lazy(() => import('./musicPlayer7/AudioPlayer2'))


const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));


// PublicRoute component for routes accessible without logging in
const PublicRoute = ({ element, ...rest }) => {
  return <Route {...rest} element={element} />;
};


const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className='container-main'>
        <Toaster position="top-center" reverseOrder={false}/>
          {/* <StoreProvider> */}
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/admin/login" element={<AdminLogin />} />

                
                <Route element={<PrivateRoutes />}>

                </Route>

                <Route element={<AdminRoutes />}>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/admin/artists" element={<ManageArtists />} />
                  <Route path="/admin/tracks" element={<ManageTracks />} />
                </Route>


                <Route path="/" element={<Home />} />
                <Route path="/organizer" element={<OrganizerAdmin />} />
                <Route path="/search/:keyword" element={<Searches />} />
                <Route path="/organizer/login" element={<OrganizerLogin />} />
                <Route path="/success" element={<Success />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/policies" element={<Policies />} />
                <Route path="/terms/es" element={<TermsES />} />
                <Route path="/policies/es" element={<PoliciesES />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/orderHistory" element={<OrderHistory />} />
                <Route path="/products/:id" element={<Detail />} />
                <Route path="/artists" element={<AllArtists />} />
                <Route path="/artists/:id" element={<ArtistDetail />} />
                <Route path="/playlist/:id" element={<PlaylistDetail />} />
                <Route path="/playlists" element={<MyPlaylists />} />
                <Route path="/events/:id" element={<Detail />} />
                <Route path="/confirm-tickets/:id" element={<ConfirmTickets />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/organizer/:id" element={<OrganizerEvent />} />
                <Route path="organizer/:id/scan" element={<Scanner />} />
                <Route path="amazingawards/vote/:categoryId" element={<Vote />} />
                <Route path="/audio" element={<AudioPlayer />} />
                <Route path="/audio2" element={<AudioPlayer2 />} />
                <Route path="/audio3" element={<AudioPlayer3 />} />
                <Route path="/audio4" element={<AudioPlayer4 />} />
                <Route path="/audio5" element={<AudioPlayer5 />} />
                <Route path="/audio6" element={<AudioPlayer6 />} />
                <Route path="/audio7" element={<AudioPlayer7 />} />

                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </Suspense>
          {/* </StoreProvider> */}
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
