import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Auth from "./auth";

export const PrivateRoutes = () => {
  const isLoggedIn = Auth.loggedIn();
  console.log(isLoggedIn ? Auth.getProfile() : "Not Logged In");

  return isLoggedIn ? <Outlet /> : <Navigate to="/admin/login" />;
};

export const AdminRoutes = () => {
  const isLoggedIn = Auth.loggedIn();
  const userProfile = isLoggedIn ? Auth.getProfile() : null;
  const isAdmin = userProfile && userProfile.data.role === 'admin';

  console.log(isLoggedIn ? userProfile : "Not Logged In");

  return isLoggedIn && isAdmin ? <Outlet /> : <Navigate to="/admin/login" />;
};